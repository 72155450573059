<template>
  <div class="">

    <form @submit.prevent="submitMatche">
      
      <div class="row">

          <div class="col-xl-3 my-3">
            <label for="price"> Client</label>
            <input type="text" :value="reservation.field?reservation.field.fullName:''" :disabled="true" class="form-control">
          </div>

          <div class="col-xl-3 my-3">
            <label for="price"> Date </label>
            <input type="date" v-model="matche.date" class="form-control">
          </div>

          <div class="col-xl-3 my-3">
            <label for="price"> Start Time</label>
            <input type="time" v-model="matche.startTime" class="form-control">
          </div>

          <div class="col-xl-3 my-3">
            <label for="price"> End Time </label>
            <input type="time" v-model="matche.endTime"  class="form-control">
          </div>

          <div class="col-xl-3 my-3">
            <label for="price"> Terrain </label>
            <select v-model="matche.terrain_reference" class="form-select ">
              <option v-for="(terrain,index) in terrains" :key="index++" :value="terrain.reference">
                {{ terrain.fullName }}
              </option>
            </select>
          </div>

          <div class="col-xl-3 my-3">
            <label for="price"> Désignation équipe A </label>
            <input type="text" v-model="matche.fullNameTeamA" class="form-control">
          </div>

          <div class="col-xl-3 my-3">
            <label for="price"> Désignation équipe B </label>
            <input type="text" v-model="matche.fullNameTeamB" class="form-control">
          </div>
      </div>
      <button type="submit" class="btn btn-primary mt-3">
        Modifier matche
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      
    };
  },
  async mounted() { 
    // if (!this.$route.params.reference) {
    //   await this.$router.push({name:'reservations-index'})
    // }
    await this.$store.dispatch("matche/show",this.$route.params.reference);
    await this.$store.dispatch("terrain/getAll");
  },
  methods: {
    async submitMatche() {
      await this.$store.dispatch("matche/update", this.matche);
    },
   
  },
  computed: {
    ...mapGetters('terrain', {
      terrains: 'getTerrains',
    }),
    ...mapGetters("reservation", {
      reservation: "getReservation",
    }), 
    ...mapGetters("matche", {
      matche: "getMatche",
    }), 
  },
};
</script>

<style scoped>

</style>
